body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.GivingTuesdayPledge--App {
  color: #221f1f;
  background-color: #fbfbfb;
  border-radius: 12px;
  padding: 40px 24px;
  line-height: 1.2;
  font-family: motiva-sans !important;
}

@media screen and (min-width: 992px) {
  .GivingTuesdayPledge--App {
    padding: 75px 160px;
  }
}

.GivingTuesdayPledge--App * {
  box-sizing: border-box;
}

.GivingTuesdayPledge--App a {
  color: #027a88;
  text-decoration: none;
}

.GivingTuesdayPledge--App a:hover {
  color: #000;
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--App .hidden-desktop {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .GivingTuesdayPledge--App .hidden-mobile {
    display: none;
  }
}

.GivingTuesdayPledge--App-layout {
  flex-direction: column;
  align-items: center;
  gap: 24px 42px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--App-layout {
    flex-direction: row;
  }
}

.GivingTuesdayPledge--App-layoutDivider {
  background-color: rgba(0, 0, 0, .2);
  align-self: stretch;
  width: 100%;
  height: 1px;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--App-layoutDivider {
    height: unset;
    width: 1px;
  }
}

.GivingTuesdayPledge--App-header {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--App-header {
    font-size: 36px;
  }
}

.GivingTuesdayPledge--App-subHeader {
  margin: 0 0 48px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--App-subHeader {
    font-size: 22px;
    line-height: 36px;
  }
}

.GivingTuesdayPledge--App-subHeader strong {
  font-weight: 700;
}

.GivingTuesdayPledge--App-header, .GivingTuesdayPledge--App-subHeader {
  text-align: center;
}

.GivingTuesdayPledge--App-header hr, .GivingTuesdayPledge--App-subHeader hr {
  border-width: medium 2px 2px;
  border-color: currentColor #fe9b8f #fe9b8f;
  border-top-style: none;
  width: 100px;
  margin: 20px auto;
}

.GivingTuesdayPledge--App-footer {
  text-align: center;
  margin-top: 32px;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--App-footer {
    margin-top: 48px;
    font-size: 15px;
    line-height: 24px;
  }
}

.GivingTuesdayPledge--Pledge-container {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--Pledge-container {
    flex: 1 0 260px;
  }
}

.GivingTuesdayPledge--Pledge-gifts {
  align-items: center;
  column-gap: 16px;
  display: flex;
}

.GivingTuesdayPledge--Pledge-giftsIcon {
  background-color: #ee4b4c;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 18px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--Pledge-giftsIcon {
    padding: 24px;
  }
}

.GivingTuesdayPledge--Pledge-giftsIcon svg {
  color: #fff;
  width: 24px;
  height: 24px;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--Pledge-giftsIcon svg {
    width: 32px;
    height: 32px;
  }
}

.GivingTuesdayPledge--Pledge-giftsCount {
  color: #000;
  height: 50px;
  font-size: 50px;
  font-weight: 800;
  line-height: 1.2;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--Pledge-giftsCount {
    height: 72px;
    font-size: 72px;
  }
}

.GivingTuesdayPledge--Pledge-giftsLabel {
  color: #575757;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--Pledge-giftsLabel {
    font-size: 28px;
  }
}

.GivingTuesdayPledge--Form {
  --pledge-gutter-x: 30px;
  --pledge-gutter-y: 30px;
  --pledge-border-radius: 0;
  --pledge-border-width: 1px;
  --pledge-border-color: #ddd;
}

.GivingTuesdayPledge--Form .row {
  margin-top: calc(-1 * var(--pledge-gutter-y));
  margin-right: calc(-.5 * var(--pledge-gutter-x));
  margin-left: calc(-.5 * var(--pledge-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.GivingTuesdayPledge--Form .row > * {
  padding-right: calc(var(--pledge-gutter-x) * .5);
  padding-left: calc(var(--pledge-gutter-x) * .5);
  margin-top: var(--pledge-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.GivingTuesdayPledge--Form .col {
  flex: none;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--Form .col {
    flex: 1 0;
  }
}

.GivingTuesdayPledge--Form .col-12 {
  flex: none;
  width: 100%;
}

.GivingTuesdayPledge--Form .form-control {
  color: #221f1f;
  -webkit-appearance: none;
  appearance: none;
  border: var(--pledge-border-width) solid var(--pledge-border-color);
  border-radius: var(--pledge-border-radius);
  background-clip: padding-box;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

.GivingTuesdayPledge--Form-intro {
  text-align: center;
  margin: 20px 0 42px;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--Form-intro {
    font-size: 22px;
  }
}

.GivingTuesdayPledge--Form-header {
  margin-bottom: 30px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--Form-header {
    font-size: 36px;
  }
}

.GivingTuesdayPledge--Form-header hr {
  border-width: medium 2px 2px;
  border-color: currentColor #fe9b8f #fe9b8f;
  border-top-style: none;
  width: 100px;
  margin: 20px auto;
}

.GivingTuesdayPledge--Form-share {
  text-align: left;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 22px 42px;
  display: flex;
}

.GivingTuesdayPledge--Form-share-img {
  flex: 0 400px;
}

.GivingTuesdayPledge--Form-share-img img {
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
}

.GivingTuesdayPledge--Form-share-content {
  flex: 1;
}

.GivingTuesdayPledge--Form-share-header {
  margin-bottom: 16px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--Form-share-header {
    font-size: 30px;
  }
}

.GivingTuesdayPledge--Form-share-body {
  margin-bottom: 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--Form-share-body {
    font-size: 22px;
  }
}

.GivingTuesdayPledge--Form-share-btn {
  display: block;
}

.GivingTuesdayPledge--Form-share-btn .btn {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--Form-share-btn {
    display: none;
  }
}

.GivingTuesdayPledge--Form-share-links {
  margin-left: -32px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  display: none;
}

@media screen and (min-width: 768px) {
  .GivingTuesdayPledge--Form-share-links {
    flex-wrap: wrap;
    row-gap: 10px;
    display: flex;
  }
}

.GivingTuesdayPledge--Form-share-links a {
  text-decoration: underline;
}

.GivingTuesdayPledge--Form-share-links svg {
  color: #027a88;
}

.GivingTuesdayPledge--Form-share-link {
  margin-left: 32px;
}

.GivingTuesdayPledge--Form-share-link:hover {
  cursor: pointer;
}

.GivingTuesdayPledge--Form .btn {
  color: #fff;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  background: #ee4b4c;
  border: none;
  border-radius: 65px;
  outline: none;
  padding: 18px 34px;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.GivingTuesdayPledge--Form .btn:hover {
  background: #920e0f;
}

.GivingTuesdayPledge--Form .btn svg {
  width: 18px;
  height: 18px;
}

.GivingTuesdayPledge--Form .btn.btn--loading #givingTuesdayPledgeFormSubmitIcon {
  display: none;
}

.GivingTuesdayPledge--Form .btn.btn--loading #givingTuesdayPledgeFormLoadingIcon, .GivingTuesdayPledge--Form .btn #givingTuesdayPledgeFormSubmitIcon {
  display: inline-block;
}

.GivingTuesdayPledge--Form .btn #givingTuesdayPledgeFormLoadingIcon {
  display: none;
}

/*# sourceMappingURL=index.css.map */
