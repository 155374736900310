$font-family-body: 'motiva-sans'!important;;

$font-color: #221F1F;
$link-color: #027A88;
$pledge-btn-color: #EE4B4C;

.GivingTuesdayPledge {
    &--App {
        color: $font-color;
        font-family: $font-family-body;
        line-height: 1.2;
        padding: 40px 24px;
        border-radius: 12px;
        background-color: #FBFBFB;

        @media screen and (min-width: 992px) {
            padding: 75px 160px;
        }

        * {
            box-sizing: border-box;
        }

        a {
            color: $link-color;
            text-decoration: none;

            &:hover {
                color: darken($link-color, 30%);
                text-decoration: underline;
            }
        }

        .hidden-desktop {
            @media screen and (min-width: 768px) {
                display: none;
            }
        }

        .hidden-mobile {
            @media screen and (max-width: 767px) {
                display: none;
            }
        }

        &-layout {
            display: flex;
            row-gap: 24px;
            column-gap: 42px;
            align-items: center;
            flex-direction: column;

            @media screen and (min-width: 768px) {
                flex-direction: row;
            }
        }

        &-layoutDivider {
            width: 100%;
            height: 1px;
            align-self: stretch;
            background-color: rgba(0, 0, 0, 0.20);

            @media screen and (min-width: 768px) {
                width: 1px;
                height: unset;
            }
        }

        &-header {
            font-size: 24px;
            font-weight: 700;
            font-style: normal;
            line-height: 1.4;

            @media screen and (min-width: 768px) {
                font-size: 36px;
            }
        }

        &-subHeader {
            font-size: 18px;
            font-weight: 300;
            margin: 0 0 48px;
            line-height: 1.4;

            @media screen and (min-width: 768px) {
                font-size: 22px;
                line-height: 36px;
            }

            strong {
                font-weight: 700;
            }
        }

        &-header,
        &-subHeader {
            text-align: center;

            hr {
                width: 100px;
                margin: 20px auto;
                border-width: 2px;
                border-color: #FE9B8F;
                border-top: none;
            }
        }

        &-footer {
            font-size: 13px;
            font-weight: 300;
            font-style: normal;
            line-height: 20px;
            margin-top: 32px;
            text-align: center;

            @media screen and (min-width: 768px) {
                font-size: 15px;
                line-height: 24px;
                margin-top: 48px;
            }
        }
    }

    &--Pledge {
        &-container {
            display: flex;
            flex-direction: column;
            gap: 16px;

            @media screen and (min-width: 768px) {
                flex: 1 0 260px;
            }
        }

        &-gifts {
            display: flex;
            align-items: center;
            column-gap: 16px;
        }

        &-giftsIcon {
            display: flex;
            padding: 18px;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            background-color: #EE4B4C;

            @media screen and (min-width: 768px) {
                padding: 24px;
            }

            svg {
                width: 24px;
                height: 24px;
                color: #fff;

                @media screen and (min-width: 768px) {
                    width: 32px;
                    height: 32px;
                }
            }
        }

        &-giftsCount {
            color: #000;
            font-size: 50px;
            font-weight: 800;
            height: 50px;
            line-height: 1.2;

            @media screen and (min-width: 768px) {
                font-size: 72px;
                height: 72px;
            }
        }

        &-giftsLabel {
            color: #575757;
            font-size: 18px;
            font-weight: 700;
            line-height: 1.4;
            text-align: left;

            @media screen and (min-width: 768px) {
                font-size: 28px;
            }
        }
    }

    &--Form {
        --pledge-gutter-x: 30px;
        --pledge-gutter-y: 30px;
        --pledge-border-radius: 0;
        --pledge-border-width: 1px;
        --pledge-border-color: #DDD;

        .row {
            display: flex;
            flex-wrap: wrap;
            margin-top: calc(-1 * var(--pledge-gutter-y));
            margin-right: calc(-.5 * var(--pledge-gutter-x));
            margin-left: calc(-.5 * var(--pledge-gutter-x));

            &>* {
                flex-shrink: 0;
                width: 100%;
                max-width: 100%;
                padding-right: calc(var(--pledge-gutter-x) * .5);
                padding-left: calc(var(--pledge-gutter-x) * .5);
                margin-top: var(--pledge-gutter-y);
            }
        }

        .col {
            flex: 0 0 auto;
            width: 100%;

            @media screen and (min-width: 768px) {
                flex: 1 0 0%;
            }

            &-12 {
                flex: 0 0 auto;
                width: 100%;
            }
        }

        .form-control {
            display: block;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: $font-color;
            appearance: none;
            background-clip: padding-box;
            border: var(--pledge-border-width) solid var(--pledge-border-color);
            border-radius: var(--pledge-border-radius);
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }

        &-intro {
            font-size: 18px;
            font-weight: 300;
            font-style: normal;
            margin: 20px 0 42px;
            text-align: center;
            line-height: 36px;

            @media screen and (min-width: 768px) {
                font-size: 22px;
            }
        }

        &-header {
            font-size: 24px;
            font-weight: 700;
            font-style: normal;
            margin-bottom: 30px;

            @media screen and (min-width: 768px) {
                font-size: 36px;
            }

            hr {
                width: 100px;
                margin: 20px auto;
                border-width: 2px;
                border-color: #FE9B8F;
                border-top: none;
            }
        }

        &-share {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            column-gap: 42px;
            row-gap: 22px;
            text-align: left;

            &-img {
                flex: 0 1 400px;

                img {
                    width: 100%;
                    max-width: 400px;
                    border-radius: 20px;
                }
            }

            &-content {
                flex: 1;
            }

            &-header {
                font-size: 24px;
                font-weight: 700;
                font-style: normal;
                margin-bottom: 16px;

                @media screen and (min-width: 768px) {
                    font-size: 30px;
                }
            }

            &-body {
                font-size: 16px;
                font-weight: 300;
                font-style: normal;
                line-height: 36px;
                margin-bottom: 32px;

                @media screen and (min-width: 768px) {
                    font-size: 22px;
                }
            }

            &-btn {
                display: block;
                
                .btn {
                    width: 100%;
                }

                @media screen and (min-width: 768px) {
                    display: none;
                }
            }

            &-links {
                display: none;
                font-size: 18px;
                font-weight: 600;
                font-style: normal;
                margin-left: -32px;

                @media screen and (min-width: 768px) {
                    display: flex;
                    flex-wrap: wrap;
                    row-gap: 10px;
                }

                a {
                    text-decoration: underline;
                }

                svg {
                    color: $link-color;
                }
            }

            &-link {
                margin-left: 32px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .btn {
            color: #fff;
            font-size: 21px;
            line-height: 21px;
            font-weight: 700;
            font-style: normal;
            border-radius: 65px;
            padding: 18px 34px;
            background: $pledge-btn-color;
            appearance: none;
            box-shadow: none;
            outline: none;
            border: none;

            &:hover {
                background: darken($pledge-btn-color, 30%);
            }

            svg {
                width: 18px;
                height: 18px;
            }

            &.btn--loading {
                #givingTuesdayPledgeFormSubmitIcon {
                    display: none;
                }

                #givingTuesdayPledgeFormLoadingIcon {
                    display: inline-block;
                }
            }

            #givingTuesdayPledgeFormSubmitIcon {
                display: inline-block;
            }

            #givingTuesdayPledgeFormLoadingIcon {
                display: none;
            }
        }
    }
}